import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrls, userAccountUrls } from '../../enum/routes';
import { Category } from '../../models/category.model';
import { Exchange } from '../../models/exchange.model';
import { FavoriteList } from '../../models/favorite.model';
import { Gateway } from '../../models/gateway.model';
import { Home } from '../../models/home.model';
import { OrderList } from '../../models/orders.model';
import { Product, ProductList, ProductSearch } from '../../models/products.model';
import { CountryDetail, Profile } from '../../models/profile.mode';
import { StoreDetail } from '../../models/store-details.model';
import { UrlBuilderService } from '../url-builder.service';
import { FAQModel, FAQResponse } from '../../models/faq.model';

@Injectable({
  providedIn: 'root',
})
export class PanelService {
  constructor(
    private http: HttpClient,
    private urlBuilder: UrlBuilderService
  ) {}

  getStoreKey(parameters): Observable<StoreDetail> {
    let params = new HttpParams();

    for (let param in parameters) {
      params = params.append(param.toString(), parameters[param]);
    }

    let url = this.urlBuilder.urlBuild(ApiUrls.storeKey);
    return this.http.get<StoreDetail>(url, { params });
  }

  getStoreDetail(): Observable<StoreDetail> {
    let url = this.urlBuilder.urlBuild(ApiUrls.storeDetail);
    return this.http.get<StoreDetail>(url)
  }

  getStoreGroups(): Observable<any> {
    let url = this.urlBuilder.urlBuild(ApiUrls.storeGroups)
    return this.http.get<any>(url)
  }

  getExchangeDetail(): Observable<Exchange[]> {
    let url = this.urlBuilder.urlBuild(ApiUrls.exchangeDetail);
    return this.http.get<Exchange[]>(url);
  }

  hiPinPayment(orderId, cardPin): Observable<any> {
    let url = this.urlBuilder.urlBuild(`purchase/orders/${orderId}/redeem`);
    return this.http.post<any>(url, cardPin);
  }

  ChargeWallethipinPayment(orderId, cardPin): Observable<any> {
    let url = this.urlBuilder.urlBuild(`finance/payments/${orderId}/redeem`);
    return this.http.post<any>(url, cardPin);
  }

  getHomeList(countryCode?: string): Observable<Home> {
    let params = new HttpParams();
    if(countryCode) {
      params = params.append('country_code', countryCode);
    }
    let url = this.urlBuilder.urlBuild(ApiUrls.home);
    return this.http.get<Home>(url, { params });
  }

  getHomeById(id): Observable<Home> {
    let url = this.urlBuilder.urlBuild(ApiUrls.homes, id);
    return this.http.get<Home>(url);
  }

  getUserProfile(): Observable<Profile> {
    let url = this.urlBuilder.urlBuild(ApiUrls.profile);
    return this.http.get<Profile>(url);
  }

  getProductList(parameters): Observable<ProductList> {
    let params = new HttpParams();

    for (let param in parameters) {
      if (parameters[param]) {
        params = params.append(param.toString(), parameters[param]);
      }
    }
    let url = this.urlBuilder.urlBuild(ApiUrls.products);
    return this.http.get<ProductList>(url, { params });
  }

  getFAQDetail(model: FAQModel, objectId?: any): Observable<FAQResponse> {
    let params = new HttpParams();
    if (objectId) {
      params = params.append('object_id', objectId);
    }
    let url = this.urlBuilder.urlBuild(ApiUrls.FAQ, model);
    return this.http.get<FAQResponse>(url, { params });
  }

  searchProductByName(name: string): Observable<ProductSearch[]> {
    let params = new HttpParams().set('search', name);
    // params = params.append('show_countries', regionId);
    let url = this.urlBuilder.urlBuild(ApiUrls.searchProductByName);
    return this.http.get<ProductSearch[]>(url, { params });
  }

  appleConnect(token): Observable<any> {
    let url = this.urlBuilder.userAccountUrlBuild(userAccountUrls.firebaseConnect);
    return this.http.post<any>(url, token);
  }

  getProductDetail(id): Observable<Product> {
    let url = this.urlBuilder.urlBuild(ApiUrls.products, id);
    return this.http.get<Product>(url);
  }

  getGatewayList(parameters?: any): Observable<Gateway[]> {
    let params = new HttpParams();

    for (let param in parameters) {
      params = params.append(param.toString(), parameters[param]);
    }

    let url = this.urlBuilder.urlBuild(ApiUrls.gateway);
    return this.http.get<Gateway[]>(url, {params});
  }

  getFavoriteList(parameters): Observable<FavoriteList> {
    let params = new HttpParams();

    for (let param in parameters) {
      params = params.append(param.toString(), parameters[param]);
    }

    let url = this.urlBuilder.urlBuild(ApiUrls.favorite);
    return this.http.get<FavoriteList>(url, { params });
  }

  getOrderList(parameters): Observable<OrderList> {
    let params = new HttpParams();

    for (let param in parameters) {
      params = params.append(param.toString(), parameters[param]);
    }

    let url = this.urlBuilder.urlBuild(ApiUrls.orders);
    return this.http.get<OrderList>(url, { params });
  }

  createOrder(order): Observable<any> {
    let url = this.urlBuilder.urlBuild(ApiUrls.orders);
    return this.http.post<any>(url, order);
  }

  getCategoryList(parameters): Observable<Category[]> {
    let params = new HttpParams();

    for (let param in parameters) {
      params = params.append(param.toString(), parameters[param]);
    }
    let url = this.urlBuilder.urlBuild(ApiUrls.category);
    return this.http.get<Category[]>(url, { params });
  }

  resendOrder(orderId): Observable<any> {
    let url = this.urlBuilder.urlBuild(
      `purchase/orders/${orderId}/resend-order`
    );
    return this.http.get<any>(url);
  }

  validatePayment(orderId): Observable<any> {
    let url = this.urlBuilder.urlBuild(
      `purchase/orders/${orderId}/payment-validation`
    );
    return this.http.get<any>(url);
  }

  validatePaymentTopUp(paymentId): Observable<any> {
    let url = this.urlBuilder.urlBuild(
      `finance/payments/${paymentId}/payment-validation`
    );
    return this.http.get<any>(url);
  }

  addToFavorite(productId): Observable<any> {
    let url = this.urlBuilder.urlBuild(
      `products/catalogs/${productId}/add-favorite`
    );
    return this.http.post<any>(url, {});
  }

  deleteFromFavorite(productId): Observable<any> {
    let url = this.urlBuilder.urlBuild(
      `products/catalogs/${productId}/delete-favorite`
    );
    return this.http.delete<any>(url);
  }

  editProfile(user): Observable<any> {
    let url = this.urlBuilder.urlBuild(ApiUrls.profile);
    return this.http.patch<any>(url, user);
  }

  WalletBalance(data?: any): Observable<any> {
    let url = this.urlBuilder.urlBuild(ApiUrls.wallet);
    return this.http.post<any>(url, data);
  }
  getWalletTransactions(data): Observable<any> {
    let url = this.urlBuilder.urlBuild(ApiUrls.walletTransactions);
    return this.http.post<any>(url, data);
  }

  getPaymentTransactions(parameters): Observable<any> {
    let params = new HttpParams();

    for (let param in parameters) {
      params = params.append(param.toString(), parameters[param]);
    }
    let url = this.urlBuilder.urlBuild(`finance/payments`);
    return this.http.post<any>(url, null, { params });
  }

  walletTransactionCheck(parameters): Observable<any> {
    let params = new HttpParams();

    for (let param in parameters) {
      params = params.append(param.toString(), parameters[param]);
    }
    let url = this.urlBuilder.urlBuild(ApiUrls.walletCheckTransactions);
    return this.http.get<any>(url, { params });
  }

  chargeWallet(data): Observable<any> {
    let url = this.urlBuilder.urlBuild(`finance/payments`);
    return this.http.post<any>(url, data);
  }
  validateWallet(id: any): Observable<any> {
    let url = this.urlBuilder.urlBuild(
      `finance/payments/${id}/payment-validation`
    );
    return this.http.get<any>(url);
  }
  payByWallet(data): Observable<any> {
    let url = this.urlBuilder.urlBuild(`purchase/orders`);
    return this.http.post<any>(url, data);
  }

  getRegionList(parameters): Observable<any> {
    let params = new HttpParams();

    for (let param in parameters) {
      params = params.append(param.toString(), parameters[param]);
    }
    let url = this.urlBuilder.urlBuild(`locations/get-store-countries`);
    return this.http.get<any>(url,{params});
  }

  getOrderDetail(orderId: any): Observable<any> {
    let url = this.urlBuilder.urlBuild(`purchase/orders/${orderId}`);
    return this.http.get<any>(url);
  }

  getBillingInfo(): Observable<any> {
    let url = this.urlBuilder.urlBuild(ApiUrls.billingAddress);
    return this.http.get<any>(url);
  }

  updateBillingAddress(user): Observable<any> {
    let url = this.urlBuilder.urlBuild(ApiUrls.billingAddress);
    return this.http.patch<any>(url, user);
  }
  getCountryList(parameters): Observable<CountryDetail[]> {
    let params = new HttpParams();

    for (let param in parameters) {
      params = params.append(param.toString(), parameters[param]);
    }
    let url = this.urlBuilder.urlBuild('locations/countries');
    return this.http.get<any>(url,{params});
  }

  getCountryCode(): Observable<any> {
    let url = this.urlBuilder.urlBuild(ApiUrls.countryCode);
    return this.http.get<any>(url);
  }

  getStateList(parameters: any): Observable<any> {
    let params = new HttpParams();
    for (let param in parameters) {
      if (parameters[param]) {
        params = params.append(param, parameters[param]);
      }
    }
    let url = this.urlBuilder.urlBuild('locations/states');
    return this.http.get(url, { params });
  }

  getCityList(parameters: any): Observable<any> {
    let params = new HttpParams();
    for (let param in parameters) {
      if (parameters[param]) {
        params = params.append(param, parameters[param]);
      }
    }
    let url = this.urlBuilder.urlBuild('locations/cities');

    return this.http.get(url, { params });
  }

  getShareLink(id): Observable<any> {
    let url = this.urlBuilder.urlBuild(`purchase/orders/${id}/get-share-link`);
    return this.http.get(url);
  }

  checkPhoneNumberExists(phone): Observable<any> {
    let url = this.urlBuilder.userAccountUrlBuild(userAccountUrls.checkPhoneExists);
    return this.http.post<any>(url, phone);
  }

  getCustomerCardsList(gatewayId):Observable<any>{
    let url = this.urlBuilder.urlBuild(`finance/verified-cards/${gatewayId}`)
    return this.http.get<any>(url)
  }

  getCardLink(gatewayId):Observable<any>{
    let url = this.urlBuilder.urlBuild(`finance/card-link/${gatewayId}`)
    return this.http.get<any>(url)
  }
  
  changeCurrency(currencyCode: string): Observable<any> {
    let url = this.urlBuilder.urlBuild(`finance/change-currency/${currencyCode}`);
    return this.http.post<any>(url, null)

  }
}
