import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) { }

  showSnackBar(message: string, theme: string, duration: number, action: string = 'Close') {
    this.snackBar.open(message, action, {
      duration: duration,
      panelClass: ['mat-toolbar', `mat-${theme}`],
    });
  }
}
